.services-dropdown-container {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: rgb(65, 30, 78);
  gap: 2rem;
  width: 100vw;
  height: 40px;
  justify-content: center;
  font-size: 17px;
}

.drop-link {
  width: auto;
  display: flex;
  color: rgb(175, 175, 175);
  text-decoration: none;
  height: 100%;
  align-items: center;
}

.drop-link:hover {
  color: white;
  transition: 0.3s;
}

@media screen and (max-width: 720px) {
  .services-dropdown-container {
    display: none;
  }
}
