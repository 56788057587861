.footer {
  margin: 0 auto;
  width: 100vw;
  background-image: url("/src/glrb.jpg");
  background-size: cover;
  background-color: rgb(0, 0, 0);
  padding: 50px 0px;
}

.copyright {
  font-size: 14px;
  color: rgb(163, 163, 163);
  margin: 0 auto;
  text-align: center;
  margin-top: 35px;
}

.oleg {
  color: rgb(197, 195, 195);
  font-size: 16px;
  text-decoration: none;
}

.oleg:hover {
  color: white;
}

.footer-content {
  margin: auto;
  padding-top: 20px;
  width: 90%;
  gap: 3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container-header {
  display: flex;
  margin-bottom: 10px;
  color: rgb(65, 30, 78);
  border-bottom: 1px solid rgb(65, 30, 78);
  padding-bottom: 0px;
  flex-direction: column;
  font-size: 20px;
}

.ul-help {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.footer-link {
  text-decoration: none;
  color: white;
}

.footer-link:hover {
  transition: 0.1s;
  color: rgb(65, 30, 78);
}

.social-icons-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
}

.social-icon {
  font-size: 30px;
}

.social-icon:hover {
  transition: 0.2s;
  scale: 1.2;
}

@media screen and (max-width: 720px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
  }

  .footer {
    background-image: none;
    padding-top: 0;
    margin-top: 1rem;
  }

  .container-header {
    color: rgb(65, 30, 78);
    margin: 0px;
    font-size: 22px;
    border-bottom: 1px solid rgb(65, 30, 78);
  }
  .footer-link {
    font-size: 18px;
  }

  .oleg {
    font-size: 0.8rem;
  }

  .copyright {
    width: 80%;
    font-size: 0.7rem;
  }
}
