.productcare-container {
  margin: 0 auto;
  width: 90vw;
}

.care {
  margin: 0 auto;
  width: 70%;
  color: white;
  justify-content: center;
}

.care-header {
  font-size: 1.5rem;
  color: rgb(132, 33, 168);
  border-bottom: 0.1rem solid rgb(132, 33, 168);
}

.rule {
  font-size: 1rem;
  margin: 0.8rem auto;
}

.span-rule {
  font-size: 1.5rem;
}

@media screen and (max-width: 720px) {
  .productcare-container {
    width: 90%;
  }

  .care {
    width: 90%;
    margin: 0 auto;
  }

  .care-header {
    width: 100%;
    text-align: center;
    font-size: 1.3rem;
  }

  .span-rule {
    font-size: 1.3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}
