.accounting-navbar-container {
  background-color: rgb(41, 41, 41);
  width: 20%;
  height: auto;
  align-items: flex-start;
  display: flex;
  margin: 0 0;
  justify-content: center;
}

.acc-inner-container {
  margin: 1rem 0;
  display: flex;
  width: 80%;
  flex-direction: column;

  align-items: center;
  gap: 2rem;
  color: white;
}

.footer-container {
  display: flex;
  flex-direction: column;
}

ul {
  padding: 0;
}

li {
  list-style: none;
  font-size: 1rem;
}

.section-container {
  margin: 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.section-sales {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.section-bills {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.dash-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  gap: 0.7rem;
  padding-left: 0.4rem;
  color: rgb(150, 148, 148);
}

.dash-link:hover {
  transition: 0.3s;
  color: white;
  translate: 0.5rem;
}

.icon-navbar {
  font-size: 0.9rem;
}

.user-container {
  margin: 0;
}

.header-txt {
  width: 100%;
  text-align: center;
  background-color: white;
  margin: 0 0;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  color: rgb(41, 41, 41);
  border-radius: 0.3rem;
}

#dashboard {
  color: rgb(224, 224, 224);
  display: flex;
  gap: 0.3rem;
  font-size: 1.5rem;
  cursor: pointer;
  align-items: center;
}

#dashboard:hover {
  transition: 0.5s;
  color: white;
}

.dash-icon {
  font-size: 1.4rem;
}

.footer-container {
  text-align: center;
  display: flex;
  gap: 0.1rem;
}

.settings {
  font-size: 1.2rem;
  cursor: pointer;
  color: rgb(213, 216, 216);
}

.logout {
  font-size: 1.2rem;
  cursor: pointer;
  color: rgb(213, 216, 216);
}

.footer-container > *:hover {
  color: white;
  scale: 1.1;
}
