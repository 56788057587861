.invoice-container {
  margin: 1rem auto;
  width: 70%;
}

.invoice-top {
  margin: 1rem auto;
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  background-color: rgb(230, 227, 227);
  border-bottom: 0.3rem solid black;
}

.invoice-hdr {
  margin: 0 auto;
  width: 70%;
  font-size: 4.1rem;
}

.invoice-bus {
  margin: 0 auto;
  width: 20%;
  font-size: 1rem;
}

.date-number {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.2rem;
}

.billto {
  margin: 1rem auto;
  display: flex;
  width: 90%;
  justify-content: flex-end;
  align-items: center;
  background-color: rgb(230, 227, 227);
  border-bottom: 0.2rem solid black;
}

.bill {
  width: 48%;
  margin: 0;
}

.bill-2 {
  width: 35%;
}
#date-invoice {
  background-color: white;
  color: black;
  border: none;
  background-color: rgb(245, 245, 245);
}

.addys {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 90%;
}

.billing {
  gap: 0.5rem;
  margin: 0 0;
  display: flex;
  flex-direction: column;
  width: 45%;
}

.shipping {
  gap: 0.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 45%;
}

.invoice-input {
  background-color: white;
  color: black;
  width: 100%;
  border: 0.1rem solid grey;
  font-size: 1rem;
}

.invoice-details {
  display: flex;
  flex-direction: row;
  gap: 0;
  flex: 1;
  border: 0.1rem solid grey;
  border-radius: 0.2rem;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-top: 0.5rem;
  width: 88%;
  padding: 0.5rem;
}

.invoice-details > * {
  text-align: center;
  margin: 0 0;
  justify-content: center;
  flex-direction: column;
}

.quantity {
  width: 12%;
}

.description {
  width: 50%;
}

.unit-cost {
  width: 12%;
}

.total {
  margin: 0 0;
  width: 12%;
}

.details-headers {
  font-size: 0.8rem;
  margin: 0 0;
}

.details-input {
  background-color: white;
  color: black;
  text-align: center;
  border: none;
  background-color: rgb(245, 245, 245);
  width: 95%;
}

.all-details {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.new-item {
  color: rgb(140, 141, 141);
  cursor: pointer;
  font-size: 1.5rem;
  margin: 0;
  align-items: center;
}

.new-item:hover {
  transition: 0.3s;
  color: black;
  transform: scale(120%);
}

.taxes-total {
  color: rgb(0, 0, 0);
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  gap: 0.2rem;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 auto;
  margin-top: 2rem;
  text-align: right;
  width: 90%;
}

.add-item-btn {
  display: flex;
  flex-direction: row;
  margin: 0 0;
  width: 10%;
  height: 3rem;
  font-size: 0.6rem;
  align-items: center;
  background-color: rgb(230, 227, 227);
  border: none;
  margin-left: 0.2rem;
}

.add-item-btn:hover {
  transition: 0.5s;
  background-color: rgb(177, 174, 174);
}

.add-item-btn:active {
  transition: 0.2s;
  background-color: rgb(245, 245, 245);
}

.delete-icon {
  font-size: 1.5rem;
}

.items-container {
  align-items: center;
}

.btn-container {
  display: flex;
  width: 80%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.create-invoice-btn {
  cursor: pointer;
  margin: 0 0;
  width: 40%;
  text-align: center;
  font-size: 1.5rem;
  background-color: rgb(230, 227, 227);
  border-radius: 0.3rem;
  border: none;
  align-self: flex-start;
}

.create-invoice-btn:hover {
  transition: 0.5s;
  background-color: rgb(0, 0, 0);
  color: white;
}

.create-invoice-btn:active {
  transition: 0.2s;
  background-color: rgb(245, 245, 245);
}

#serv {
  border: none;
  width: 100%;
}

.items-list {
  margin: 0 auto;
  margin-top: 0rem;
  width: 90%;
}

.listed-items {
  margin-top: 0.2rem;
  border: none;
}

.items-list > * {
  font-size: 1rem;
}

.service {
  width: 15%;
  background-color: black;
  color: white;
  font-size: 0.8rem;
}

.descr-txt {
  display: flex;
  overflow: auto;
  text-overflow: ellipsis;
  resize: none;
  width: 90%;
  border: none;
}

.txt-des {
  margin: 0 auto;
  width: 50%;
}

.delete-btn {
  cursor: pointer;
  font-size: 1.5rem;
  color: rgb(99, 99, 99);
}

@keyframes shake {
  from {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(-15deg);
  }

  to {
    transform: rotate(15deg);
  }
}

.delete-btn:hover {
  transition: 0.5s;
  color: black;
  animation-duration: 0.5s;
  animation-name: shake;
  animation-iteration-count: infinite;
  scale: 1.2;
}

.delete-btn:active {
  transition: 0.2s;
  color: rgb(99, 99, 99);
}

.item-div {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wholesale-retail {
  text-align: center;
}

.shipping-same {
  margin: 0 0;
  width: 20%;
  display: flex;
  align-items: center;
}
