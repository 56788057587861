.windowcard-container {
  margin: 0 auto;
  width: 85%;
  height: 1.5rem;
  display: flex;
  gap: 0.5rem;
  color: white;
  align-items: center;
}

.hedr {
  font-size: 0.85rem;
  width: 30%;
}

.btn-window-notes {
  width: 4rem;
  margin: 0 0;
  font-size: 0.75rem;
  background-color: black;
}

.close-icon {
  font-size: 1.2rem;
}

.close-icon:hover {
  transition: 0.3s;
  color: red;
  scale: 130%;
}

.modal-active {
  display: block;
}

.active-window {
  padding: 0.2rem;
  border: 0.1rem solid rgb(65, 30, 78);
}

@media screen and (max-width: 720px) {
  .windowcard-container {
    width: 95%;
    gap: 0;
  }

  .hedr {
    font-size: 0.9rem;
  }

  .btn-window-notes {
    font-size: 0.8rem;
  }

  .window-qty {
    width: 20%;
  }

  .close-icon {
    font-size: 1.5rem;
  }
}
