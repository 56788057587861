body {
  background-color: black;
}

.services-page-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100vw;
  gap: 3rem;
  margin-top: 20px;
}

.page-header {
  margin: 0 auto;
  width: 100vw;
  text-align: center;
  margin-top: 30px;
  font-size: 2.5rem;
  color: white;
}

.service-div {
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  width: 90%;
  border-bottom: 1px solid rgb(65, 30, 78);
}

.name-service {
  margin: 0 auto;
  font-size: 2.5rem;
  width: 94%;
}

.service-description {
  width: 90%;
  font-size: 25px;
  margin-left: 20px;
}

.text-side-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  color: white;
}

.service-img {
  width: 40%;
  height: 400px;
  overflow: hidden;
  object-fit: cover;
}

.buttons-container {
  margin: 0 auto;
  margin-top: 20px;
  gap: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button {
  padding: 0px 30px;
  margin: 0 auto;
  font-size: 25px;
  width: 100%;
  text-align: center;
  background-color: black;
}

.button:hover {
  background-color: black;
  animation: svg-shadow 1.5s ease-in-out infinite alternate;
}

.button:active {
  background-color: rgb(65, 30, 78);
}

@keyframes svg-shadow {
  from {
    filter: drop-shadow(0 0 3px #fff) drop-shadow(0 0 3px rgb(65, 30, 78))
      drop-shadow(0 0 5px rgb(65, 30, 78));
  }

  to {
    filter: drop-shadow(0 0 5px #fff) drop-shadow(0 0 5px rgb(65, 30, 78))
      drop-shadow(0 0 5px rgb(65, 30, 78));
  }
}

@media screen and (max-width: 720px) {
  .services-page-container {
    gap: 2rem;
  }

  .service-img {
    display: none;
  }

  .page-header {
    margin-top: 90px;
    margin-bottom: 0px;
    background-color: white;
    color: black;
  }

  .service-div {
    padding: 0px;
    flex-direction: column;
    justify-content: flex-start;
  }

  .service-description {
    font-size: 18px;
  }

  .name-service {
    font-size: 2rem;
    width: 85%;
    text-align: center;
  }

  .text-side-div {
    flex-direction: column;
    width: 100%;
  }

  .buttons-container {
    margin-bottom: 20px;
  }

  .button {
    padding: 5px 10px;
    font-size: 18px;
    width: 100%;
  }
}
