.calculator-outer-continer {
  margin: 0 auto;
  width: 100vw;
  background-color: black;
}

.calculator-inner-container {
  margin: 2.5rem auto;
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: row;
}

.calc-leftside {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: flex-start;
  gap: 1rem;
}

.descript {
  font-size: 1rem;
  color: white;
}

.leftside-header {
  font-size: 2rem;
  text-align: start;
  color: white;
  border-bottom: 0.1rem solid white;
  margin-bottom: 1.5rem;
}

.calc-rightside {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  border-left: 0.1rem solid rgb(165, 164, 164);
}

.rightside-header {
  margin: 0 0;
  color: white;
  font-size: 2rem;
  width: 60%;
  text-align: center;
  margin-bottom: 1.5rem;
  border-bottom: 0.1rem solid white;
}

.leftside-description {
  display: flex;
  flex-direction: column;
}

.label-descript {
  color: white;
}

.form-inputs {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  gap: 1rem;
}

.addwindow-btn {
  font-size: 1rem;
  width: 23%;
  height: 100%;
  background-color: black;
  color: white;
  border: 0.1rem solid rgb(65, 30, 78);
  border-radius: 0.2rem;
}

.addwindow-btn:hover {
  transition: 0.3s;
  background-color: rgb(65, 30, 78);
}

.addwindow-btn:active {
  background-color: black;
}

.range-container {
  margin: 0 auto;
  display: flex;
  gap: 1rem;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

#range-checkbox {
  margin: 0 0;
  width: 1rem;
}

.range-header {
  color: white;
}

.range-counter {
  display: flex;
  width: 100%;
  gap: 2rem;
}

.addrange-btn {
  margin: 0 0;
  width: 30%;
  background-color: black;
}

#range-input {
  width: 30%;
}

input {
  width: 20%;
  border-radius: 0.2rem;
  background-color: black;
  border: 0.1rem solid rgb(65, 30, 78);
  color: white;
}

::placeholder {
  font-size: 0.85rem;
}

#notes {
  width: 96%;
  height: 3rem;
  resize: none;
  background-color: black;
  border: 0.1rem solid rgb(65, 30, 78);
  border-radius: 0.2rem;
  color: white;
  font-size: 1rem;
}

.window-list-container {
  display: flex;
  width: 100%;
  gap: 0.1rem;
  flex-direction: column;
}

.testr-cont {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.testr {
  color: white;
  font-size: 1.2rem;
}

.grandtotal-price {
  font-size: 2rem;
  color: white;
}

.taxes {
  color: white;
  font-size: 1.2rem;
}

.price-tax {
  width: 92%;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.btn-checkout {
  width: 50%;
  margin: 0;
  background-color: black;
}

@media screen and (max-width: 720px) {
  .calculator-inner-container {
    flex-direction: column;
  }

  .calc-leftside {
    width: 90%;
    margin: 0 auto;
  }

  .leftside-header {
    width: 100%;
    font-size: 1.1rem;
    text-align: center;
    margin: 0 auto;
  }

  .form-inputs {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }

  .addwindow-btn {
    font-size: 1rem;
    margin: 0 auto;
    width: 100%;
    height: 2.2rem;
    position: relative;
    top: 4.8rem;
    border-radius: 0.2rem;
    background-color: rgb(65, 30, 78);
    border: none;
    margin-left: 0.6rem;
    color: white;
  }

  .addwindow-btn:active {
    transition: 0.3s;
    background-color: black;
  }

  .addrange-btn:focus {
    background-color: black;
  }

  .input-field {
    margin: 0 auto;
    width: 100%;
    text-align: center;
  }

  #notes {
    width: 100%;
    margin: 0 auto;
    position: relative;
    bottom: 3.2rem;
    text-align: center;
    height: 4rem;
  }

  ::placeholder {
    margin: 0 auto;
    font-size: 1.1rem;
    padding: 0;
  }

  .range-container {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    position: relative;
    bottom: 0.7rem;
    font-size: 1.1rem;
  }

  .range-counter {
    width: 100%;
    margin: 0 auto;
    gap: 1.7rem;
    justify-content: center;
    position: relative;
    bottom: 1rem;
  }

  #range-input {
    width: 50%;
  }

  .addrange-btn {
    width: 40%;
    font-size: 0.8rem;
    height: 2.3rem;
  }

  .calc-rightside {
    width: 90%;
    margin-top: 1rem;
    border: none;
    border-top: 0.1rem solid white;
  }

  .rightside-header {
    font-size: 2rem;
    width: 100%;
  }

  .window-list-container {
    width: 100%;
  }
}
