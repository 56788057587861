.faq-container {
  margin: 0 auto;
  width: 90%;
  justify-content: center;
  padding: 2rem;
}

.faq-header {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 1rem;
  color: white;
  font-size: 2rem;
}

.questions-container {
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  justify-content: center;
}
.question-container {
  width: 100%;
}

.faq-question {
  display: flex;
  gap: 0.5rem;
  color: white;
  font-size: 1.3rem;
  align-items: center;
  cursor: pointer;
}

.faq-question:hover {
  transition: 0.3s;
  color: rgb(143, 82, 165);
}

.question-icon {
  cursor: pointer;
}

.question-icon:hover {
  transition: 0.2s;
  color: rgb(65, 30, 78);
}

.list-container {
  width: 90%;
  margin: 0 auto;
  align-self: center;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 720px) {
  .faq-header {
    width: 90%;
    font-size: 1.3rem;
    border-bottom: 0.1rem solid white;
  }

  .faq-container {
    width: 90%;
  }

  .questions-container {
    width: 100%;
    gap: 1rem;
  }

  .faq-question {
    font-size: 0.8rem;
  }

  .question-icon {
    font-size: 1rem;
  }
}
