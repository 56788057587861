.reportwindow-container {
  position: fixed;
  justify-content: center;
  margin: 0;
  width: 80%;
  height: 69%;
  background-color: white;
  overflow-y: scroll;
}

.container-top {
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

.container-bottom {
  width: 100%;
  display: flex;
}

.hdr {
  margin: 0;
  font-size: 2rem;
  background-color: rgb(41, 41, 41);
  color: white;
  width: 100%;
}

.btn-closerep {
  margin: 0 auto;
  width: 50%;
}
