.about-container {
  display: flex;
  width: 80%;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  text-align: center;
}

.about-main-container {
  width: 100vw;
  margin: auto;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.about-header {
  font-size: 2.5rem;
  margin: 0;
  color: white;
}

.about-text {
  color: white;
  text-align: center;
}
.btn {
  margin: auto;
}

.btn-about {
  margin: auto;
  color: white;
  text-align: center;
  width: 40%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(65, 30, 78);
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
}

.btn-about:hover {
  background-color: rgb(65, 30, 78);
  border: none;
  transition: 0.5s;
}

.btn-about:active {
  background-color: black;
  transition: 0s;
}

@media screen and (max-width: 720px) {
  .btn-about {
    width: 80%;
    font-size: 1.1rem;
  }
}
