.news-card-container {
  padding: 10px;
  width: 20%;
  border-radius: 5px;
}

.news-img-container {
  width: 100%;
}

.news-content-container {
  margin-bottom: 5px;
}

.news-card-img {
  height: 150px;
  width: 100%;
  object-fit: fill;
}

.text-container {
  color: white;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
  width: auto;
  height: 68px;
}

.news-header {
  margin: auto;
  font-size: 20px;
  color: rgb(65, 30, 78);
}

.but-cont {
  text-align: center;
}

.btn-news {
  width: 100%;
  color: white;
  border-color: rgb(65, 30, 78);
  font-size: 15px;
  background-color: black;
}

.btn-news:hover {
  background-color: rgb(65, 30, 78);
}

@media screen and (max-width: 720px) {
  .news-card-container {
    width: 80%;
    height: auto;
    padding: 0px;
    margin: auto;
  }

  .news-header {
    font-size: 20px;
  }

  .news-card-img {
    width: 100%;
    height: 250px;
  }
}
