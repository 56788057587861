.team-container {
  width: 100vw;
  margin: 0;
  text-align: center;
  padding-bottom: 2rem;
}

.team-hdr {
  margin: 0;
  width: 100%;
  background-color: rgb(65, 30, 78);
  margin-bottom: 2rem;
  color: white;
  font-size: 2rem;
}

.team-list {
  margin: 0 auto;
  width: 50%;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  /* border: 0.1rem solid white; */
}

@media screen and (max-width: 720px) {
  .team-list {
    width: 90%;
    flex: row;
    gap: 2rem;
  }
}
