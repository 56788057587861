.services-main-container {
  width: 100vw;
  height: auto;
  margin: 0 auto;
}

.services-container {
  display: flex;
  width: 85%;
  gap: 1rem;
  margin: 0 auto;
}

.header-container {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 5px;
}

.service-header {
  color: white;
  margin: 0 auto;
  font-size: 2.5rem;
}

.services-icon {
  font-size: 32px;
}

@media screen and (max-width: 720px) {
  .services-main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .services-container {
    flex-direction: column;
    justify-content: center;
    margin: 0;
    margin-right: 25px;
  }
}
