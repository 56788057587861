.notesmodal-container {
  border-radius: 0.2rem;
  text-align: center;
  position: fixed;
  right: 50%;
  top: 20%;
  width: 45%;
  height: 22rem;
  background-color: black;
  border: 0.1rem solid rgb(65, 30, 78);
  display: none;
}

.modal-header {
  font-size: 1rem;
  color: white;
}

.modal-active {
  display: block;
}

.btn-closemodal {
  position: relative;
  top: 21%;
  width: 70%;
  background-color: black;
}

.modal-text {
  margin: 0.5rem auto;
  width: 95%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.modal-size {
  font-size: 1.2rem;
  color: white;
}

.modal-price {
  color: white;
}

@media screen and (max-width: 720px) {
  .notesmodal-container {
    position: fixed;
    width: 87%;
    height: 60vh;
    top: 8rem;
    right: 1rem;
    margin: 0 auto;
  }
}
