.btn {
  width: 100%;
  margin: 0 auto;
  color: white;
  border: 1px solid rgb(65, 30, 78);
  border-radius: 5px;
  font-size: 20px;
  background-color: white;
  cursor: pointer;
  align-items: center;
}

a {
  text-decoration: none;
}

.linkec {
  display: flex;
}
