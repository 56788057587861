.service-card {
  display: flex;
  text-decoration: none;
  flex-direction: column;
  align-items: center;
  width: 20%;
  margin: 0 auto;
  border: 2px solid rgb(65, 30, 78);
  cursor: pointer;
  padding: 5px;
}

.service-card:hover {
  position: relative;
  outline: none;
  box-shadow: 0 0 10px white;
  transition: 0.5s;
  transform: translateY(-10px);
  border-color: white;
  background-color: black;
}

.service-image {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.service-name {
  position: relative;
  margin: 0 auto;
  width: 90%;
  margin-top: 8px;
  background-color: black;
  color: white;
  font-size: 18px;
  white-space: nowrap;
  text-align: center;
}

@media screen and (max-width: 1020px) {
  .service-name {
    font-size: 12px;
  }
}

@media screen and (max-width: 720px) {
  .service-card {
    text-align: center;
    width: 100%;
    border: 1px solid rgb(65, 30, 78);
  }

  .service-card:hover {
    position: inherit;
    outline: none;
    box-shadow: none;
    transition: none;
    transform: none;
    border-color: white;
    background-color: black;
  }

  .service-name {
    position: inherit;
    width: 90%;
    font-size: 1.5rem;
    padding: 5px;
    margin: 0 auto;
    background-color: black;
  }
  .service-image {
    width: 100%;
    height: auto;
  }
}
