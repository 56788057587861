.member-pic {
  width: 130px;
  border-radius: 0.2rem;
}

.member {
  color: white;
  font-size: 1.3rem;
}

.user-card {
  display: flex;
  gap: 2rem;
}

.member-info {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media screen and (max-width: 720px) {
  .member {
    font-size: 1rem;
  }

  .member-info {
  }
}
