.simulator-box {
  margin-bottom: 5rem;
  background-color: white;
}

.simulator-selector {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: row;
  background-color: black;
  color: white;
  text-align: center;
}

.decider {
  margin: 0 auto;
  display: flex;
  width: 90%;
  justify-content: center;
}

.simulator-tint-header {
  width: 50%;
  font-size: 30px;
  text-align: center;
  justify-content: center;
  padding: 17px;
}

.simulator-tint-header:hover {
  cursor: pointer;
  background-color: rgb(65, 30, 78);
  color: white;
}

.header-clicked {
  background-color: rgb(65, 30, 78);
  color: white;
}

.simulator-ppf-header:hover {
  cursor: pointer;
  background-color: rgb(65, 30, 78);
  color: white;
}

.simulator-ppf-header {
  font-size: 30px;
  width: 50%;
  text-align: center;
  padding: 17px;
}

@media screen and (max-width: 720px) {
  .simulator-selector {
    position: relative;
    z-index: 5;
  }

  .simulator-box {
    position: relative;
    top: 20px;
  }

  .simulator-ppf-header {
    font-size: 1rem;
  }

  .simulator-tint-header {
    font-size: 1rem;
  }
}
