.reports-main-container {
  margin: 0 0;
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.reports-header {
  width: 100%;
  margin: 0 auto;
  padding: 1rem 0;
  font-size: 1.7rem;
  text-align: center;
  background-color: rgb(41, 41, 41);
  color: white;
}

.header-rep {
  font-size: 1.5rem;
}

.report-container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  text-align: center;
}

.rep-btn {
  background-color: rgb(169, 170, 170);
  border-radius: 0.3rem;
  min-height: 3rem;
  height: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rep-btn:hover {
  transition: 0.3s;
  scale: 1.1;

  cursor: pointer;
  background-color: black;
  color: white;
}

.allreps {
  margin: 0 auto;
  margin-top: 2rem;
  width: 95%;
  display: flex;
  gap: 3rem;
  justify-content: center;
}

.allreps > * {
  width: 20%;
}

.cashflow-reports {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 1.3rem;
  font-size: 1.6rem;
  width: 100%;
  height: 3rem;
  text-align: center;
  background-color: rgb(212, 211, 211);
}
