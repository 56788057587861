.home-page {
  width: 100vw;
  margin: 0 auto;
  background-color: black;
}

@media screen and (max-width: 720px) {
  .home-page {
    width: 100vw;
  }

  .main-video {
    display: none;
  }
}
