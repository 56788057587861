.news-cards-container {
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.news-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.news-header-container {
  margin: 0 auto;
  width: 80%;
}

.news-header-main {
  display: flex;
  margin: auto;
  color: white;
  font-size: 2.5rem;
  align-items: center;
  justify-content: center;
}

.news-icon {
  margin: 0;
  padding: 0;
  font-size: 36px;
}

@media screen and (max-width: 720px) {
  .news-container {
    display: none;
  }
  .news-cards-container {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 90%;
  }
}
