.autotint-main-container {
  margin: 0 auto;
  width: 100vw;
  margin: 2rem 0rem;
  padding: 2rem 0rem;
}

.autotint-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0 auto;
  width: 90%;
}

.autotint-header {
  font-size: 2.9rem;
  padding-bottom: 1rem;
  color: white;
}

.tint-description {
  font-size: 1.2rem;
  color: white;
  text-align: center;
}

.tint-description-lower {
  font-size: 1.5rem;
  color: white;
  padding: 0 0.5rem;
  border-bottom: 0.1rem solid white;
}

.tint-topper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}

@media screen and (max-width: 720px) {
  .autotint-main-container {
    width: 100%;
  }

  .tint-description-lower {
    font-size: 1.2rem;
    text-align: center;
  }

  .autotint-header {
    font-size: 2rem;
  }

  .tint-topper {
    padding-bottom: 0;
  }
}
