.brands-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: black;
  text-align: start;
  width: 100vw;
  height: auto;
  margin: 0 auto;
  margin-top: 70px;
  padding: 0;
  border-top: 2px solid white;
}

.brands-header {
  align-items: center;
  color: white;
  font-size: 2rem;
}

.trust-icon {
  margin: 0 auto;
  font-size: 25px;
}

.brands-logos-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  width: 100vw;
  justify-content: center;
  height: 100px;
  background-color: white;
  margin: 0 auto;
  padding: 20px 0px;
  padding: 0px;
}

.brand-logo {
  margin: 0 auto;
  height: 50%;
  color: white;
}

@media screen and (max-width: 720px) {
  .brand-logo {
    width: 15%;
    height: auto;
  }

  .brands-logos-container {
    width: 100vw;
    justify-content: space-around;
    gap: 0rem;
  }

  .brands-header {
    font-size: 2rem;
    width: 90%;
  }
  .header-container {
    text-align: center;
    width: 100%;
  }
}
